import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PerfilGuard } from './core/service/perfil-guard.service';
import { Perfil } from './core/utils/perfil';

const routes: Routes = [
    {
        path: 'talonarios',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./talonarios/talonarios.module').then(
                (m) => m.TalonariosModule
            ),
    },
    {
        path: 'remessas',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO,
                Perfil.UNIDADE_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./remessas/remessas.module').then(
                (m) => m.RemessasModule
            ),
    },
    {
        path: 'distribuicoes-talonarios-policiais',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.UNIDADE_TALONARIO,
                Perfil.POLICIAL_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./distribuicoes-talonarios-policiais/distribuicoes-talonarios-policiais.module').then(
                (m) => m.DistribuicoesTalonariosPoliciaisModule
            ),
    },

    {
        path: 'solicitar-talonario-detran',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./solicitar-talonario-detran/solicitar-talonario-detran.module').then(
                (m) => m.SolicitarTalonarioDetranModule
            ),
    },  
    {
        path: 'solicitar-talonario-pm',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.UNIDADE_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./solicitar-talonario-pm/solicitar-talonario-pm.module').then(
                (m) => m.SolicitarTalonarioPmModule
            ),
    },

    {
        path: 'distribuir-talonario-unidade',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./distribuicoes-talonarios-unidade/distribuicoes-talonarios-unidade.module').then(
                (m) => m.DistribuicoesTalonariosUnidadeModule
            ),
    },
    
    {
        path: 'autoinfracao',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.POLICIAL_TALONARIO,
                Perfil.POLICIAL_TALONARIO,
                Perfil.GESTAO_TALONARIO,
                Perfil.UNIDADE_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./autoinfracao/autoinfracao.module').then(
                (m) => m.AutoinfracaoModule
            ),
    },

    {
        path: 'quantidade-autos-infracao/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./quantidade-autos-infracao/quantidade-autos-infracao.module').then(
                (m) => m.QuantidadeAutosInfracaoModule
            ),
    },
    
    {
        path: 'ranking-infracoes/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.UNIDADE_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./ranking-infracoes/ranking-infracoes.module').then(
                (m) => m.RankingInfracoesModule
            ),
    },
    {
        path: 'historico-distribuicao-talonario/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.UNIDADE_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./historico-distribuicao-talonario/historico-distribuicao-talonario.module').then(
                (m) => m.HistoricoDistribuicaoTalonarioModule
            ),
    },
    {
        path: 'consultar-remessa/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.UNIDADE_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./consultar-remessa/consultar-remessa.module').then(
                (m) => m.ConsultarRemessaModule
            ),
    },
    {
        path: 'consultar-auto-infracao/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.UNIDADE_TALONARIO, Perfil.POLICIAL_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./consultar-auto-infracao/consultar-auto-infracao.module').then(
                (m) => m.ConsultarAutoInfracaoModule
            ),
    },
    {
        path: 'consultar-quantidade-infracao/relatorio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.UNIDADE_TALONARIO, Perfil.POLICIAL_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./consultar-quantidade-infracao/consultar-quantidade-infracao.module').then(
                (m) => m.ConsultarQuantidadeInfracaoModule
            ),
    },
    {
        path: 'inicio',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO, Perfil.POLICIAL_TALONARIO, Perfil.UNIDADE_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./pagina-inicial/pagina-inicial.module').then(
                (m) => m.PaginaInicialModule
            ),
    },

    // Configurações

    {
        path: 'configuracao/tipo-infracao',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./tipo-infracao/tipo-infracao.module').then(
                (m) => m.TipoInfracaoModule
            ),
    },

    {
        path: 'configuracao/notificacao',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./notificacao/notificacao.module').then(
                (m) => m.NotificacaoModule
            ),
    },

    {
        path: 'configuracao/teste-alcoolemia',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO
            ],
        },
        loadChildren: () =>
            import('./teste-alcoolemia/teste-alcoolemia.module').then(
                (m) => m.TesteAlcoolemiaModule
            ),
    },
    {
        path: 'curso-atualizacao',
        canActivate: [PerfilGuard],
        data: {
            perfil: [
                Perfil.GESTAO_TALONARIO,
            ],
        },
        loadChildren: () =>
            import('./curso-atualizacao/curso-atualizacao.module').then(
                (m) => m.CursoAtualizacaoModule
            ),
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [PerfilGuard],
})
export class AppRoutingModule {}
